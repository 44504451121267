import { Store, stores } from '@strategies/stores';
import { action, observable, makeObservable } from 'mobx';

import Stores from './Stores';
import { FILE_EXT } from './FileStore';


export default class UIStore extends Store {

    constructor() {
        super();

        makeObservable(this);
    }

    @observable
    appRef?: HTMLDivElement;

    @action
    setAppRef(appRef: HTMLDivElement) {
        this.appRef = appRef;
    }

    @observable
    isAssembliesOpen: boolean = true;

    @action
    setAssembliesOpen(open: boolean = true) {
        this.isAssembliesOpen = open;
    }

    @observable
    isAnalysisOpen: boolean = true;

    @action
    setAnalysisOpen(open: boolean = true) {
        this.isAnalysisOpen = open;
    }

    @observable
    isAssembliesAnalysisOpen: boolean = true;

    @action
    setAssembliesAnalysisOpen(open: boolean = true) {
        this.isAssembliesAnalysisOpen = open;
    }

    @observable
    isInfoModalOpen: boolean = false;

    @action
    setInfoModalOpen(open: boolean = true) {
        this.isInfoModalOpen = open;
    }

    @observable
    inStartup: boolean = true;

    @action
    setInStartup(inStartup: boolean = true) {
        this.inStartup = inStartup;
    }

    @action
    openFileLoadDialog() {
        return new Promise<void>(resolve => {
            const { file } = stores as Stores;
            const input = document.createElement('input');

            input.type = "file";
            input.accept = `.${FILE_EXT}, .${FILE_EXT}.json`;

            input.addEventListener('change', () => {
                const reader = new FileReader();
                reader.onload = (e: ProgressEvent) => {
                    const data = JSON.parse(reader.result as string);
                    file.load(data);
                    resolve();
                };
                reader.readAsText(input.files![0]);

                input.remove();
            });

            input.click();
        })
    }

}
