import { Store, stores } from '@strategies/stores';
import { applyPatches, onPatches, Patch } from 'mobx-keystone';
import { action, computed, makeObservable, observable } from 'mobx';

import Stores from '../stores/Stores';
import FileStore from './FileStore';
import PatchGroup from '../models/PatchGroup';


export const MAX_QUEUE_LENGTH = 20;


export default class HistoryStore extends Store {

    constructor() {
        super();
        makeObservable(this);
    }

    @observable
	public isGrouping: any = false;

    @observable
	public queue: PatchGroup[] = [];

    @action
    setQueue(queue: PatchGroup[], resetHead: boolean = false) {
        this.queue = queue;

        if (resetHead) {
            this._setHead(queue.length - 1);
        }
    }

    @observable
	public head: number = -1;

    private _file?: FileStore;
    private _unwatch?: any;

    @action
    watch(file: FileStore) {
        this._file = file;

        if (this._unwatch) {
            this._unwatch();
        }

		this._unwatch = onPatches(this._file, (patches: Patch[], inversePatches: Patch[]) => {
			if (this.isGrouping) {
                this.queue[this.head].pushPatches(patches);
                this.queue[this.head].pushInversePatches(inversePatches);
			}
		});
    }

	@computed
	get group(): PatchGroup|undefined {
		return this.head >= 0 ? this.queue[this.head] : undefined;
    }

    @action
	startGroup() {
		if (!this.isGrouping) {
            const { assemblies } = stores as Stores;
            const _queue = [...this.queue];

            if (_queue.length >= MAX_QUEUE_LENGTH) {
                _queue.shift();
                this._setHead(this.head - 1);
            }

            _queue.length = this.head + 1;
            const patch = new PatchGroup({ carbonStartValue: assemblies.carbon });
            this.setQueue([ ..._queue, patch ]);
            this._setHead(this.head + 1);

            this._setIsGrouping();
        }
	}

    @action
	stopGroup(result: string){
        const { assemblies } = stores as Stores;

		this._setIsGrouping(false);

        this.group!.setResult(result);
        this.group!.setCarbonEndValue(assemblies.carbon);

        if (this.group!.patches.length === 0) {
            this.queue.pop();
            this._setHead(this.head - 1);
        }
	}

    @action
	cancelGroup() {
		this._setIsGrouping(false);
        this.undo();
		this.queue.pop();
	}

    @action
    undo() {
        if (this._file && this.canUndo) {
			applyPatches(this._file, [...this.group!.inversePatches].reverse());
            this._setHead(this.head - 1);
        }
    }
    
    @action
    redo() {
        if (this._file && this.canRedo) {
            this._setHead(this.head + 1);
			applyPatches(this._file, this.group!.patches);
        }
    }

    @computed
    get canRedo() {
		return !this.isGrouping && this.head < this.queue.length - 1;
    }

    @computed
    get canUndo() {
		return !this.isGrouping && this.head >= 0;
    }

    @action
    private _setHead(head: number) {
        this.head = head;
    }

    @action
    private _setIsGrouping(isGrouping = true) {
        this.isGrouping = isGrouping;
    }

    @action
    reset() {
        this._setHead(-1);
        this.queue.length = 0;
    }

}
