import { useCallback } from 'react';
import { observer } from 'mobx-react';
import { useStores } from '@strategies/stores';
import { Modal, Title, Body, Buttons, Button } from '@strategies/ui';

import Stores from '../../../../stores/Stores';


export default observer(function InfoModal() {
    const { ui } = useStores<Stores>();

    const onClose = useCallback(() => ui.setInfoModalOpen(false), [ui]);

    return (
        <Modal className="InfoModal" active={ui.isInfoModalOpen} onClose={onClose}>
            <Title>Info</Title>
            <Body>
            </Body>
            <Buttons>
                <Button onClick={onClose}>
                    Done
                </Button>
            </Buttons>
        </Modal>
    );
});
