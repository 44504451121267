import { v4 as uuidv4 } from 'uuid';
import { stores, Store } from '@strategies/stores';
import Notification from '@strategies/notification';
import { applySnapshot, getSnapshot, Model, model, objectMap, prop, registerRootStore, SnapshotInOf } from 'mobx-keystone';

import Stores from './Stores';
import data from '../assets/assemblies.json';
import { capitalize, flatten } from '../util';
import Assembly from '../models/Assembly';
import Type from '../models/Type';
import Component from '../models/Component';
import Scenario from '../models/Scenario';


export const FILE_EXT = 'emco';

@model('FileStore')
class FileStore extends Model({
    id: prop<string>(() => uuidv4()),
    name: prop<string>('').withSetter(),
    scenario: prop<Scenario>(() => new Scenario({})),
    assemblies: prop<Assembly[]>(() => (
        Object.entries(data).map(([assemblyName, types]) => new Assembly({ 
            name: capitalize(assemblyName), 
            types: Object.entries(types).map(([typeName, components]: any) => new Type({
                name: capitalize(typeName),
                components: Object.entries(components).map(([componentName, { products, ...values } ]: any) => {
                    return new Component({ 
                        name: capitalize(componentName), 
                        products: objectMap<number>(Object.entries(products || {})),
                        ...values,
                    });
                }),
            })),
        }))
    )),
}) implements Store {

    onRegister() {
        registerRootStore(this);
        this._refreshStores();
    }
    onUpdateRegistration() {}


    load(data: object & { name: string }) {
        applySnapshot<FileStore>(this, data as SnapshotInOf<FileStore>);
        new Notification(`Loaded "${data.name}.${FILE_EXT}"`);
        this._refreshStores();
    }

    save() {
        const { history } = stores as Stores;
        const date = new Date();
        const fileName = `${date.getFullYear()}-${date.getMonth()}-${date.getDay()}-${Date.now() % 100000}`;

        this.setName(fileName);
        this.scenario.setHistory(history.queue);

        const snapshot = getSnapshot<FileStore>(this);
        const blob = new Blob([JSON.stringify(snapshot)], { 'type': 'application/embodied-carbon+json' });

        const a = document.createElement('a');
        a.href = window.URL.createObjectURL(blob);
        a.download = `${fileName}.${FILE_EXT}`;
        
        a.click();
        a.remove();

        new Notification(`Saved "${this.name}.${FILE_EXT}"`);
    }

    private _refreshStores() {
        const { assemblies, history, types, components } = stores as Stores;

        history.watch(this);
        history.setQueue(this.scenario.history, true);

        assemblies.setItems(this.assemblies);

        types.setItems(
            this.assemblies
            .map(assembly => assembly.types)
            .reduce(flatten, [])
        );

        components.setItems(
            this.assemblies
            .map(assembly => assembly.types)
            .reduce(flatten, [])
            .map(t => t.components)
            .reduce(flatten, [])
        );
    }

}


export default FileStore;
