import { stores } from '@strategies/stores';
import { action, computed, makeObservable } from 'mobx';

import Stores from './Stores';
import Controller from './Controller';
import Assembly from '../models/Assembly';
import { Hierarchy, StringMap, sum } from '../util';

export default class AssembliesStore extends Controller<Assembly> {

    constructor() {
        super();

        makeObservable(this);
    }

    @computed
    get impactColors() {
        return (stores as Stores).colors.impact(this.all.map(a => a.carbon));
    }

    @action
    setActive(item?: Assembly) {
        this.deactivateAll();

        if (item) {
            item.setActive();
        }
    }

    @computed
    get byName(): StringMap<Assembly> {
        return this.all.reduce((items, item) => ({ ...items, [item.name]: item }), {} as StringMap<Assembly>);
    }

    @computed
    get carbon() {
        return this.all.map(a => a.carbon).reduce(sum);
    }

    @computed
    get hierarchy(): Hierarchy {
        return {
            name: 'root',
            children: this.all.map(a => a.hierarchy),
        };
    }

}
