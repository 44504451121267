import React from 'react';
import ReactDOM from 'react-dom/client';
import { register } from '@strategies/stores';

import './index.scss';
import App from './components/App';

/**
 * Stores
 */

import AssembliesStore from './stores/AssembliesStore';
import ColorStore from './stores/ColorStore';
import ComponentsStore from './stores/ComponentsStore';
import ConfigStore from './stores/ConfigStore';
import FileStore from './stores/FileStore';
import HistoryStore from './stores/HistoryStore';
import TypesStore from './stores/TypesStore';
import UIStore from './stores/UIStore';
import UserStore from './stores/UserStore';

register({
    assemblies: new AssembliesStore(),
    colors: new ColorStore(),
    components: new ComponentsStore(),
    config: new ConfigStore(),
    file: new FileStore({}),
    history: new HistoryStore(),
    types: new TypesStore(),
    ui: new UIStore(),
    user: new UserStore(),
});


/**
 * Mount
 */

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
