import { observer } from 'mobx-react';
import { Input } from '@strategies/react-form';

import Assembly from '../../models/Assembly';
import { formatCarbon } from '../../util';


type AssemblyProps = {
    assembly: Assembly;
    color?: string;
};

export default observer(function Assembly({ assembly, color }: AssemblyProps) {
    return (
        <div className="Assembly">
            {color && (
                <span className="color" style={{ backgroundColor: color }} />
            )}

            <h3>{assembly.name}</h3>

            <Input
                type="number"
                readonly={true}
                right={assembly.unit}
                value={assembly.size}
            />

            <div className="pill-wrapper">
                <span className="pill" style={{ backgroundColor: assembly.impactColor }}>
                    {formatCarbon(assembly.carbon)}
                </span>
            </div>
        </div>
    );
})
