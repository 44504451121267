import { Store } from '@strategies/stores';
import { action, computed, makeObservable, observable } from 'mobx';


type Model = {
    active: boolean;
    setActive(active: boolean): void;
}

export default class Controller<T extends Model> extends Store {

    constructor() {
        super();
        makeObservable(this);
    }

    @observable
    all: T[] = [];

    @action
    setItems(items: T[]) {
        this.all = observable(items);
    }

    @action
    add(item: T) {
        this.all.push(item);
    }

    @action
    remove(item: T) {
        this.all.splice(this.all.indexOf(item), 1);
    }

    @action
    deactivateAll(): void {
        this.all.forEach(item => item.setActive(false));
    }

    @computed
    get active(): T | undefined {
        return this.all.filter(item => item.active)[0];
    }

}
