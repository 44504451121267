import { action, computed, observable } from 'mobx';
import { stores } from '@strategies/stores';
import { scaleLinear } from '@visx/scale';
import { Model, model, prop, getParent } from 'mobx-keystone';

import { Hierarchy, sum, SPACE_UNIT } from '../util';
import Assembly from './Assembly';
import Component from './Component';
import Stores from '../stores/Stores';


@model('Type')
class Type extends Model({
    name: prop<string>(),
    components: prop<Component[]>(),
    color: prop<string>(''),
    size: prop<number>(0).withSetter(),
}) {

    onAttachedToRootStore() {
        if (this.color === '') {
            this.color = (stores as Stores).colors.fromGroup(this.parent.name, this.name);
        }
    }

    @observable
    active: boolean = false;

    @action
    setActive(active: boolean = true) {
        this.active = active;
    }

    @computed
    get carbon() {
        return this.components.map(c => c.carbon).reduce(sum);
    }

    get parent(): Assembly {
        return getParent<Assembly>(getParent<Type[]>(this)!)!;
    }

    @computed
    get highestComponentCarbon() {
        return Math.max(...this.components.map(c => c.carbon));
    }

    @computed
    get impactColor(): string {
        return (stores as Stores).types.impactColors(this.carbon);
    }

    @computed
    get colorScale() {
        return scaleLinear<string>({
            domain: [0, this.highestComponentCarbon],
            range: ['#fff', this.color],
        });
    }

    @computed
    get percentageOfAssembly() {
        return 100 * this.carbon / this.parent.carbon;
    }

    @computed
    get hierarchy(): Hierarchy {
        return {
            name: this.name,
            color: this.color,
            percentage: this.percentageOfAssembly,
            children: this.components.map(({ name, carbon, color }) => ({ name, carbon, color })),
        };
    }

    @computed
    get unit() {
        if (this.components.length > 0) {
            return this.components[0].unit;
        }

        return SPACE_UNIT;
    }

}


export default Type;
