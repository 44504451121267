import { observer } from 'mobx-react';
import { useStores } from '@strategies/stores';
import { Button, IconButton, MenuBar as UIMenuBar, Logo, Body } from '@strategies/ui';
import { FiFolder, FiLogOut, FiSave, FiSettings, FiRotateCcw, FiRotateCw } from 'react-icons/fi'; 

import Stores from '../../stores/Stores';
import InfoModal from './modals/InfoModal/InfoModal';
import EmbodiedCarbonLogo from '../../assets/embodied-carbon-logo.png';



export default observer(function MenuBar() {
    const { file, history, ui, user } = useStores<Stores>();

    return <>
        <UIMenuBar drawer={false}> 
            <Logo><img src={EmbodiedCarbonLogo} alt="Embodied Carbon" /></Logo>
            <Body>
                {/*
                <IconButton
                    onClick={() => ui.setInfoModalOpen()}
                >
                    <FiInfo />
                </IconButton>
                  */}

                <IconButton
                    onClick={() => ui.setInStartup()}
                >
                    <FiSettings />
                </IconButton>

                <IconButton
                    onClick={() => ui.openFileLoadDialog()}
                >
                    <FiFolder />
                </IconButton>

                <IconButton
                    onClick={() => file.save()}
                >
                    <FiSave />
                </IconButton>

                {file.name && (
                    <h6><span>Scenario:</span> {file.name}</h6>
                )} 

                <div className="right">
                    <IconButton
                        onClick={() => history.undo()}
                        disabled={!history.canUndo}
                    >
                        <FiRotateCcw />
                    </IconButton>

                    <IconButton
                        onClick={() => history.redo()}
                        disabled={!history.canRedo}
                    >
                        <FiRotateCw />
                    </IconButton>

                    <Button className="secondary" onClick={() => user.logout()}>
                        Logout <FiLogOut />
                    </Button>
                </div>
            </Body>
        </UIMenuBar>

        <InfoModal />
    </>;
});
