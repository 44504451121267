import { observer } from 'mobx-react';
import { useStores } from '@strategies/stores';

import Stores from '../../stores/Stores';
import { MAX_QUEUE_LENGTH } from '../../stores/HistoryStore';
import { EqualityStates, formatCarbon, getEquality } from '../../util';


export default observer(function ModelHistory() {
    const { history } = useStores<Stores>();

    const patches = [...history.queue.filter(group => group.isComplete).slice(0, history.head + 1)].reverse();
    const largestCarbonDelta = patches.reduce((a,b) => Math.max(a,Math.abs(b.carbonDelta)), 0);

    return (
        <div className="Widget ModelHistory">
            <h4>Model History</h4>

            <div className="insight">
                <span>Insight</span>
                The previous {MAX_QUEUE_LENGTH} decisions are recorded here.
            </div>

            {patches.length > 0 && <>
                <div>
                    <ul>
                        {patches.map((patch, i) => {
                            const equality = EqualityStates[getEquality(patch.carbonDelta)];

                            return (
                                <li key={patch.id}>
                                    <equality.arrow className={equality.className} />
                                    <div className="pill-wrapper">
                                        <span className={`pill ${equality.className}`}>{formatCarbon(patch.carbonDelta)}</span>
                                    </div>
                                    <span>{patch.result}</span>
                                </li>
                            );
                        })}
                    </ul>
                </div>

                <div className="bar-chart">
                    {patches.map((patch, i) => {
                        const equality = EqualityStates[getEquality(patch.carbonDelta)];

                        return (
                            <div 
                                key={patch.id}
                                className={`bar ${equality.className}`} 
                                style={{
                                    height: `${100 * Math.abs(patch.carbonDelta) / largestCarbonDelta}%`,
                                    flex: `0 0 calc((100% / ${MAX_QUEUE_LENGTH}) - 10px)`,
                                    width: `calc((100% / ${MAX_QUEUE_LENGTH}) - 10px)`,
                                }}
                            />
                        );
                    })}
                </div>
            </>}
        </div>
    );
});
