import { observer } from 'mobx-react';
import { useStores } from '@strategies/stores';

import Stores from '../../stores/Stores';
import { formatCarbon } from '../../util';
import ComponentModel from '../../models/Component';


type ComponentProps = {
    component: ComponentModel;
};

export default observer(function Component({ component }: ComponentProps) {
    const { history } = useStores<Stores>();

    const componentName = (
        component.parent.name === component.name 
        || component.name.startsWith(component.parent.name)
            ? component.name 
            : `${component.parent.name} ${component.name}`
    );

    // console.log(Array.from(component.products.keys()))

    return (
        <li className="Component">
            <h4>{component.name}</h4>

            <select 
                value={component.rate} 
                onChange={e => {
                    history.startGroup();
                    component.setRate(e.target.value);
                    history.stopGroup(`${componentName} set to ${component.rate}`);
                }}
            >
                <optgroup label="Market">
                    {Object.keys(component.marketRate).map(value => (
                        <option key={value} value={value}>{value}</option>
                    ))}
                </optgroup>
                {component.products && <optgroup label="Products">
                    {Array.from(component.products.keys()).map(value => (
                        <option key={value} value={value}>{value}</option>
                    ))}
                </optgroup>}
            </select>

            <div className="pill-wrapper">
                <span className="pill" style={{ backgroundColor: component.impactColor }}>
                    {formatCarbon(component.carbon)}
                </span>
            </div>
        </li>
    );
});
