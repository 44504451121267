import { observer } from 'mobx-react';
import { useCallback, useEffect, useState } from 'react';
import { Panel, Toggle, Title, Body } from '@strategies/ui';
import { useStores } from '@strategies/stores';
import { FiBarChart2 } from 'react-icons/fi';
import { Group } from '@visx/group';
import { Treemap, treemapSquarify, hierarchy } from '@visx/hierarchy';

import { formatCarbon, StringMap } from '../../util';
import Stores from '../../stores/Stores';
import AssemblyRatioAnalysis from '../AssemblyRatioAnalysis/AssemblyRatioAnalysis';


const STROKE_WIDTH = 4;


type Dims = {
    width: number;
    height: number;
};

type Selection = {
    width: string;
    height: string;
    left: string;
    top: string;
    opacity: number;
}

const DEFAULT_SELECTION: Selection = {
    width: '0%', 
    height: '0%',
    left: '50%',
    top: '50%',
    opacity: 0,
};

export default observer(function AssembliesAnalysis() {
    const { assemblies, types, ui } = useStores<Stores>();
    const [container, setContainer] = useState<HTMLDivElement|null>(null);
    const [dims, setDims] = useState<Dims>({ width: 0, height: 0 });
    const [refs, setRefs] = useState<StringMap<SVGGElement>>({});
    const [selection, setSelection] = useState<Selection>(DEFAULT_SELECTION);

    const refKeys = Object.keys(refs).reduce((a,b) => `${a}${b}`, '');

    const setRef = useCallback((name: string, ref: SVGGElement) => {
        setRefs({ ...refs, [name]: ref });
    }, [refs]);

    useEffect(() => setRefs({}), [assemblies.active]);

    useEffect(() => {
        if (types.active && types.active.carbon > 0 && container && refs[types.active.name]) {
            const ref = refs[types.active.name];

            const resize = () => {
                const { width, height, left, top } = ref.getBoundingClientRect();
                const containerLeft = container.getBoundingClientRect().left;
                const containerTop = container.getBoundingClientRect().top;

                setSelection({
                    width: `${width - (STROKE_WIDTH / 2)}px`,
                    height: `${height - (STROKE_WIDTH / 2)}px`,
                    left: `${left - containerLeft + (STROKE_WIDTH / 2)}px`,
                    top: `${top - containerTop + (STROKE_WIDTH / 2)}px`,
                    opacity: 1,
                });
            };

            resize();

            const resizeObserver = new ResizeObserver(resize);
            resizeObserver.observe(ref);

            return () => resizeObserver.unobserve(ref);
        }
        else {
            setSelection(DEFAULT_SELECTION);
        }
    }, [container, refs, refKeys, types.active]);


    useEffect(() => {
        if (container) {
            const resize = () => {
                const { width, height } = container.getBoundingClientRect();
                setDims({ width, height });
            };

            resize();

            const resizer = new ResizeObserver(resize);
            resizer.observe(container);
            return () => resizer.unobserve(container);
        }
    }, [container]);

    const group = assemblies.active || assemblies;

    const data = (
        hierarchy(group.hierarchy)
            .sum(d => d.carbon ?? 0)
            .sort((a,b) => (b.value || 0) - (a.value || 0))
    );

    const depth = !!assemblies.active ? 2 : 1;

    const background = '#114b5f';

    return (
        <Panel 
            className="AssembliesAnalysis" 
            active={ui.isAssembliesAnalysisOpen}
            onToggle={() => ui.setAssembliesAnalysisOpen(!ui.isAssembliesAnalysisOpen)}
        >
            <Toggle><FiBarChart2 /></Toggle>
            <Title>Assemblies Analysis</Title>
            <Body>
                <AssemblyRatioAnalysis />

                <div className="treemap-container" ref={el => el && !container && setContainer(el)}>
                    {group.carbon > 0 && <>
                        <svg width={dims.width} height={dims.height}>
                            <Treemap
                                root={data}
                                size={[dims.width, dims.height]}
                                tile={treemapSquarify}
                            >
                                {(treemap: any) => (
                                    <Group>
                                        {treemap
                                            .descendants()
                                            .reverse()
                                            .map((node: any, i: number) => {
                                                const nodeWidth = node.x1 - node.x0;
                                                const nodeHeight = node.y1 - node.y0;

                                                return (
                                                    <Group
                                                        key={i}
                                                        top={node.y0}
                                                        left={node.x0}
                                                    >
                                                        {node.depth === depth && <>
                                                            <rect
                                                                strokeWidth={STROKE_WIDTH}
                                                                width={nodeWidth}
                                                                height={nodeHeight}
                                                                stroke={background}
                                                                fill={node.data.color}
                                                            />

                                                            {depth === 1 && (
                                                                <NodeData node={node} />
                                                            )}
                                                        </>}

                                                        {depth !== 1 && node.depth === depth - 1 && <>
                                                            <rect
                                                                ref={el => el && !refs[node.data.name] && setRef(node.data.name, el)}
                                                                width={nodeWidth}
                                                                height={nodeHeight}
                                                                fill={'transparent'}
                                                                stroke={'transparent'}
                                                            />

                                                            <NodeData node={node} />
                                                        </>}
                                                    </Group>
                                                );
                                            })
                                        }
                                    </Group>
                                )}
                            </Treemap>
                        </svg>

                        <div 
                            className="selection"
                            style={selection}
                        />
                    </>}
                </div>
            </Body>
        </Panel>
    );
});

type NodeDataProps = {
    node: {
        data: { name: string, percentage: number };
        value: number;
    };
};

function NodeData({ node }: NodeDataProps) {
    return <>
        <text className="bold">{node.data.name}</text>
        <text>{node.data.percentage.toFixed(2)}%</text>
        <text>{formatCarbon(node.value)}</text>
    </>;
}
