import { useState } from 'react';
import { observer } from 'mobx-react';
import { useStores } from '@strategies/stores';
import { Input } from '@strategies/react-form';
import { FiChevronRight } from 'react-icons/fi';
import { Header, List, ListMode, Accordion, Title, Toggle, Body } from '@strategies/ui';

import Stores from '../../stores/Stores';
import Assembly from '../../models/Assembly';
import Component from '../Component/Component';
import { formatCarbon, getDirection, getEquality  } from '../../util';


type TypesProps = {
    assembly: Assembly;
};

export default observer(function Types({ assembly }: TypesProps) {
    const { history, types } = useStores<Stores>();
    const [startSize, setStartSize] = useState<number>(0);

    return (
        <List 
            className="Types"
            mode={ListMode.RADIO} 
            value={types.active?.name}
            onChange={(name: string) => types.setActive(types.byName[name])}
        >
            {assembly.types.map(t => (
                <Accordion key={t.name} value={t.name} color={t.color}>
                    <Title>{t.name}</Title>
                    <Header>
                        <Input
                            className="small"
                            type="number"
                            right={t.unit}
                            value={t.size}
                            onFocus={() => { setStartSize(t.size); history.startGroup(); }}
                            onBlur={() => history.stopGroup(`${t.name} quantity ${getDirection(getEquality(t.size - startSize))}`)}
                            onChange={v => t.setSize(parseInt(v as string))}
                        />

                        <div className="pill-wrapper">
                            <span className="pill" style={{ backgroundColor: t.impactColor }}>
                                {formatCarbon(t.carbon)}
                            </span>
                        </div>
                    </Header>
                    <Toggle><FiChevronRight /></Toggle>
                    <Body>
                        <ul className="components">
                            {t.components.map(component => (
                                <Component key={component.name} component={component} />
                            ))}
                        </ul>
                    </Body>
                </Accordion>
            ))}
        </List>
    );
});
