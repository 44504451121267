import { stores } from '@strategies/stores';
import { action, computed, makeObservable } from 'mobx';

import Stores from './Stores';
import Controller from './Controller';
import Type from '../models/Type';


export default class TypesStore extends Controller<Type> {

    constructor() {
        super();
        makeObservable(this);
    }

    @action
    setActive(item: Type) {
        this.all.forEach(item => item.setActive(false));
        item.setActive();
    }

    @action
    toggleActive(item: Type) {
        this.all.forEach(_item => {
            if (_item.name === item.name) {
                _item.setActive(!_item.active);
            }
            else {
                _item.setActive(false);
            }
        });
    }

    @computed
    get byName(): {[key:string]: Type} {
        return this.all.reduce((a,b) => ({ ...a, [b.name]: b }), {});
    }

    @computed
    get impactColors() {
        return (stores as Stores).colors.impact(this.all.map(t => t.carbon));
    }

}
