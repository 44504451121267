/**
 * Util
 *
 * Utility functions and constants.
 */

import { FaGreaterThan, FaLessThan, FaEquals } from 'react-icons/fa';
import { FiArrowDown, FiArrowUp } from 'react-icons/fi';


export const CARBON_UNIT = "kgCO₂";
export const SPACE_UNIT = "FT²";
export const FEET_TO_METER = 10.76; 

export type Dict<T> = {[key:string]: T};

export enum Equality {
    LESS,
    GREATER,
    EQUAL,
};

export const EqualityStates = {
    [Equality.LESS]: {
        className: 'less',
        icon: FaLessThan,
        arrow: FiArrowDown
    },
    [Equality.GREATER]: {
        className: 'greater',
        icon: FaGreaterThan,
        arrow: FiArrowUp,
    },
    [Equality.EQUAL]: {
        className: 'equal',
        icon: FaEquals,
        arrow: FaEquals,
    },
};

export function getEquality(delta: number) {
    if (delta > 0) {
        return Equality.GREATER;
    }
    if (delta < 0) {
        return Equality.LESS;
    }
    return Equality.EQUAL;
}

export function getDirection(equality: Equality) {
    return ({
        [Equality.LESS]: 'decreased',
        [Equality.GREATER]: 'increased',
        [Equality.EQUAL]: 'stayed the same',
    })[equality];
}

export type Hierarchy = {
    carbon?: number;
    children?: Hierarchy[];
    color?: string;
    name: string;
    percentage?: number;
};

export type StringMap<T extends any = string> = {
    [key: string]: T
};

export function capitalize(s: string) {
    return s.charAt(0).toUpperCase() + s.slice(1);
}

export const flatten = (a: any[], b: any[]) => [...a, ...b];

export function formatCarbonNumber(carbon: number) {
    if (carbon === 0) {
        return `0`;
    }

    return `${parseFloat(carbon.toFixed(2)).toLocaleString()}`;

}

export function formatCarbon(carbon: number) {
    return `${formatCarbonNumber(carbon)} ${CARBON_UNIT}`;
}

export function noop() {}

export function stopPropagation(e: any) {
    e.stopPropagation();
}

export function sum(a: number, b: number) {
    return a + b;
}
