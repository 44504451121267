import { stores } from '@strategies/stores';
import { computed, makeObservable } from 'mobx';

import Stores from './Stores';
import Controller from './Controller';
import Component from '../models/Component';


export default class ComponentsStore extends Controller<Component> {

    constructor() {
        super();
        makeObservable(this);
    }

    @computed
    get impactColors() {
        return (stores as Stores).colors.impact(this.all.map(t => t.carbon));
    }

}
