import { observer } from 'mobx-react';
import { useStores } from '@strategies/stores';
import { ReactLogin, SasakiForm, EmailForm } from '@strategies/react-login';
import Logo from '../assets/embodied-carbon-logo.png';

import Stores from '../stores/Stores';


export default observer(function Login() {
    const { firebase } = useStores<Stores>().config;

    return (
        <ReactLogin
            logo={Logo}
            title={'Embodied Carbon'}
            forms={[
                new SasakiForm(firebase),
                new EmailForm(firebase),
            ]}
            screens={[]}
        />
    );
});
