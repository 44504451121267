/**
 * Scenario Model
 *
 * This is where the initial project settings are defined by the user
 * when stepping through the welcome sequence.
 */

import { Model, model, prop } from 'mobx-keystone';

import PatchGroup from './PatchGroup';


@model('Scenario')
class Scenario extends Model({
    totalProjectSize: prop<number>(0).withSetter(),
    openOfficeSeats: prop<number>(30).withSetter(),
    privateOfficeSeats: prop<number>(5).withSetter(),
    conferenceSeats: prop<number>(15).withSetter(),
    history: prop<PatchGroup[]>(() => []).withSetter(),
    baseline: prop<number>(0).withSetter(),
}) {
}


export default Scenario;
