import { Store } from '@strategies/stores';


export default class ConfigStore extends Store {

    readonly firebase = {
        apiKey: "AIzaSyAz4xLfgpU5T0jJDJDBdW2e-Fg_m7Hw5CM",
        authDomain: "embodied-carbon-9c718.firebaseapp.com",
        projectId: "embodied-carbon-9c718",
        storageBucket: "embodied-carbon-9c718.appspot.com",
        messagingSenderId: "312185256070",
        appId: "1:312185256070:web:3e2f287ecb10a0b714f907"
    };

}
