/*
 * PatchGroup
 *
 * Serializable data for applying incremental changes to the file.
 */


import { v4 as uuidv4 } from 'uuid';
import { action, computed } from 'mobx';
import { Model, model, Patch, prop } from 'mobx-keystone';

import { getDirection, getEquality } from '../util';


@model('PatchGroup')
class PatchGroup extends Model({
    id: prop<string>(() => uuidv4()),
    result: prop<string>('').withSetter(),
    patches: prop<Patch[]>(() => []),
    inversePatches: prop<Patch[]>(() => []),
    carbonStartValue: prop<number>(),
    carbonEndValue: prop<number>(0).withSetter(),
}) {

    @computed
    get isComplete() {
        return this.result !== '';
    }

    @computed
    get carbonDelta() {
        return this.carbonEndValue - this.carbonStartValue;
    }

    @computed
    get carbonDirection() {
        return getDirection(getEquality(this.carbonDelta));
    }

    @action
    pushPatches(patches: Patch[]) {
        this.patches.push(...patches);
    }

    @action
    pushInversePatches(patches: Patch[]) {
        this.inversePatches.push(...patches);
    }

}

export default PatchGroup;
