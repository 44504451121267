import { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useStores } from '@strategies/stores';

import Stores from '../stores/Stores';
import Startup from './Startup/Startup';
import MenuBar from './MenuBar/MenuBar';
import Assemblies from './Assemblies/Assemblies';
import AssembliesAnalysis from './AssembliesAnalysis/AssembliesAnalysis';
import Analysis from './Analysis/Analysis';
import Login from './Login';


export default observer(function App() {
    const stores = useStores<Stores>();

    useEffect(() => {
        if (process.env.NODE_ENV !== 'production') {
            // @ts-ignore
            window.stores = stores;
        }
    }, [stores]);

    return (
        <div 
            className="EmbodiedCarbonApp"
            ref={el => el && !stores.ui.appRef && stores.ui.setAppRef(el)}
        >
            {stores.user.isLoggedIn ? <>
                <Startup />
                <MenuBar />
                <Assemblies />
                <AssembliesAnalysis />
                <Analysis />
            </> : <>
                <Login />
            </>}
        </div>
    );
});
