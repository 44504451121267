import { useCallback, useState } from 'react';
import { observer } from 'mobx-react';
import { useStores } from '@strategies/stores';
import { FiArrowRight, FiClipboard } from 'react-icons/fi';
import { Modal, Body, Button } from '@strategies/ui';
import { Input } from '@strategies/react-form';

import { noop, SPACE_UNIT } from '../../util';
import Stores from '../../stores/Stores';
import Logo from '../../assets/embodied-carbon-logo.png';


export default observer(function Startup() {
    const { file, ui } = useStores<Stores>();
    const [step, setStep] = useState<number>(0);
    const { scenario } = file;

    const next = useCallback(() => {
        if (step === 1) {
            ui.setInStartup(false);
        }
        else {
            setStep(1);
        }
    }, [step, setStep, ui]);

    return (
        <Modal 
            className="Startup"
            active={ui.inStartup} 
            onClose={noop} 
            mount={ui.appRef}
        >
            <Body>
                <img src={Logo} alt="Embodied Carbon App Logo" />

                {step === 0 && <>
                    <p>
                        This product allows you to create designs from inputs or import existing design data in order analyze and make material and impactful decisions for design projects.
                    </p>

                    <div className="get-started">
                        <h2>Get Started</h2>

                        <div className="get-started-options">
                            <Button onClick={async () => { 
                                await ui.openFileLoadDialog();
                                ui.setInStartup(false);
                            }}>
                                <FiClipboard />
                                Generate From Existing Data
                            </Button>

                            <Button className="secondary" onClick={next}>
                                <FiClipboard />
                                Generate From Create New
                            </Button>
                        </div>
                    </div>

                    <h3>What's New</h3>

                    <p>
                        Share your file with other collaborators
                    </p>
                </>}

                {step === 1 && <>
                    <section>
                        <p>
                            Let's start by inputting your overall gross sqft project size ...
                        </p>

                        <Input
                            type="number"
                            name="Total Project Size"
                            right={SPACE_UNIT}
                            value={scenario.totalProjectSize}
                            onChange={(v: string|number) => scenario.setTotalProjectSize(parseInt(v as string))}
                        />
                    </section>

                    <div className="continue">
                        <Button disabled={!scenario.totalProjectSize} onClick={next}>
                            <span>Continue</span>
                            <FiArrowRight />
                        </Button>
                    </div>
                </>}

                {/*step === 2 && <>
                    <section>
                        <p>
                            Let's start by inputting your overall gross sqft project size ...
                        </p>

                        <Input 
                            name="Open Office Seats"
                            className="columned"
                            type="increment"
                            right="#"
                            value={scenario.openOfficeSeats}
                            onChange={v => scenario.setOpenOfficeSeats(parseInt(v as string))}
                        />

                        <Input 
                            name="Private Office Seats"
                            className="columned"
                            type="increment"
                            right="#"
                            value={scenario.privateOfficeSeats}
                            onChange={v => scenario.setPrivateOfficeSeats(parseInt(v as string))}
                        />

                        <Input 
                            name="Conference Seats"
                            className="columned"
                            type="increment"
                            right="#"
                            value={scenario.conferenceSeats}
                            onChange={v => scenario.setConferenceSeats(parseInt(v as string))}
                        />
                    </section>

                    <div className="continue">
                        <Button onClick={next}>
                            <span>Continue</span>
                            <FiArrowRight />
                        </Button>
                    </div>
                </>*/}
            </Body>
        </Modal>
    );
});
