import { Store } from '@strategies/stores';
import { scaleQuantize } from '@visx/scale';


export default class Colors extends Store {

    private assigned: {[key: string]: string} = {};
    private groups: {[key: string]: string[]} = {};

    public fromGroup(group: string, key: string): string {
        if (!(key in this.assigned)) {
            if (key in this.overrides) {
                this.assigned[key] = this.overrides[key];
            }
            else {
                if (!(group in this.groups)) {
                    this.groups[group] = [ ...this.colors ];
                }

                const idx = Math.floor(Math.random() * this.groups[group].length);
                const color = this.groups[group].splice(idx, 1)[0];

                this.assigned[key] = color;
            }
        }

        return this.assigned[key];
    }

    public readonly impactColorRange = ['#80FAB1', '#FAFA80', '#FAC580', '#FB613E'];

    public impact(values: number[]) {
        const domain = [Math.min(...values), Math.max(...values)];
        const range = domain[1] === 0 ? [this.impactColorRange[0], this.impactColorRange[0]] : this.impactColorRange;

        // @ts-ignore
        return scaleQuantize<string>({ domain, range });
    }

    private readonly colors: string[] = [
        '#E6CBEA',
        '#D9E7A4',
        '#B3DBF7',
        '#FAE2B3',
        '#C3ECF4',
        '#DDE6BF',
        '#9BBEC4',
        '#EBBCBA',
        '#C5B8C8',
    ];

    private readonly overrides: {[key:string]: string} = {

        // Floors
        'Concrete': '#AEB4C1',
        'Carpet': '#F6C9B1',

    };

}
