import { observer } from 'mobx-react';
import { Panel, Toggle, Title, Header, Body, Button } from '@strategies/ui';
import { useStores } from '@strategies/stores';
import { FiBarChart2, FiRefreshCcw } from 'react-icons/fi';

import Stores from '../../stores/Stores';
import Insight from '../Insight/Insight';
import ModelHistory from '../ModelHistory/ModelHistory';
import { FEET_TO_METER, formatCarbon, CARBON_UNIT } from '../../util';


export default observer(function Analysis() {
    const { assemblies, file, history, ui } = useStores<Stores>();

    return (
        <Panel 
            className="Analysis" 
            active={ui.isAnalysisOpen}
            onToggle={() => ui.setAnalysisOpen(!ui.isAnalysisOpen)}
        >
            <Toggle><FiBarChart2 /></Toggle>
            <Title>Analysis</Title>
            <Header>
                <Button
                    onClick={() => {
                        file.scenario.setBaseline(assemblies.carbon);
                        history.reset();
                    }}
                >
                    <span>BASELINE</span>
                    <span>{formatCarbon(file.scenario.baseline)}</span>
                    <FiRefreshCcw />
                </Button>
            </Header>
            <Body>
                <Insight 
                    name={`Total Embodied Carbon (${CARBON_UNIT})`}
                    leftValue={assemblies.carbon} 
                    rightValue={file.scenario.baseline}
                />

                <Insight 
                    name={`Embodied Carbon per Project Area (${CARBON_UNIT} / m²)`}
                    leftValue={FEET_TO_METER * assemblies.carbon / (file.scenario.totalProjectSize || 1)} 
                    rightValue={FEET_TO_METER * file.scenario.baseline / (file.scenario.totalProjectSize || 1)}
                />

                <ModelHistory />
            </Body>
        </Panel>
    );
});
