import { computed } from 'mobx';
import { stores } from '@strategies/stores';
import { getParent, Model, model, prop, ObjectMap } from 'mobx-keystone';

import Type from './Type';
import Stores from '../stores/Stores';
import { Dict } from '../util';


@model('Component')
class Component extends Model({
    name: prop<string>(),
    unit: prop<string>(''),
    high: prop<number>(-1).withSetter(),
    avg: prop<number>(-1).withSetter(),
    low: prop<number>().withSetter(),
    rate: prop<string>('Market Average').withSetter(),
    products: prop<ObjectMap<number>>().withSetter(),
}) {

    onInit() {
        if (this.high === -1) {
            this.setHigh(this.low);
        }
        if (this.avg === -1) {
            this.setAvg(this.low);
        }
    }

    active = false;
    setActive(x: boolean) {}

    @computed
    get marketRate(): Dict<number> {
        return {
            'Market High': this.high,
            'Market Average': this.avg,
            'Market Low': this.low,
        };
    }

    @computed
    get carbon() {
        const rateScalar = (this.products && this.products.has(this.rate) ? this.products.get(this.rate) : this.marketRate[this.rate]) || 0;
        return rateScalar * (this.parent.size || 0);
    }

    @computed
    get color() {
        return this.parent.colorScale(this.carbon);
    }

    @computed
    get impactColor() {
        return (stores as Stores).components.impactColors(this.carbon);
    }

    get parent(): Type {
        return getParent<Type>(getParent<Component[]>(this)!)!;
    }

}


export default Component;
