import { action, computed, observable } from 'mobx';
import { Model, model, prop } from 'mobx-keystone';
import { stores } from '@strategies/stores';

import Type from './Type';
import Stores from '../stores/Stores';
import { Hierarchy, sum, SPACE_UNIT } from '../util';


@model('Assembly')
class Assembly extends Model({
    name: prop<string>(),
    types: prop<Type[]>(),
    color: prop<string>(''),
}) {

    onAttachedToRootStore() {
        if (this.color === '') {
            this.color = (stores as Stores).colors.fromGroup('all', this.name);
        }
    }

    @observable
    active: boolean = false;

    @action
    setActive(active: boolean = true) {
        this.active = active;
    }

    @computed
    get carbon(): number {
        return this.types.map(t => t.carbon).reduce(sum);
    }

    @computed
    get impactColor(): string {
        return (stores as Stores).assemblies.impactColors(this.carbon);
    }

    @computed
    get size(): number {
        return this.types.map(t => t.size).reduce(sum);
    }

    @computed
    get percentageOfTotal() {
        const { assemblies } = stores as Stores;
        return 100 * this.carbon / assemblies.carbon;
    }

    @computed
    get hierarchy(): Hierarchy {
        return {
            name: this.name,
            color: this.color,
            percentage: this.percentageOfTotal,
            children: this.types.map(t => t.hierarchy),
        };
    }

    @computed
    get unit() {
        if (this.types.length > 0) {
            return this.types[0].unit;
        }

        return SPACE_UNIT;
    }
}


export default Assembly;
