import { observer } from 'mobx-react';
import { useStores } from '@strategies/stores';
import { FiClipboard } from 'react-icons/fi';
//import { RadioSlider } from '@strategies/react-form';
import { Panel, Toggle, Title, Header, Body, RadioSlider } from '@strategies/ui';

import Types from '../Types/Types';
import Stores from '../../stores/Stores';
import Assembly from '../Assembly/Assembly';


export default observer(function Assemblies() {
    const { assemblies, types, ui } = useStores<Stores>();

    return (
        <Panel 
            className="AssembliesPanel" 
            active={ui.isAssembliesOpen}
            onToggle={() => ui.setAssembliesOpen(!ui.isAssembliesOpen)}
        >
            <Toggle><FiClipboard /></Toggle>
            <Title>Primary Assemblies</Title>

            <Header>
                <RadioSlider 
                    value={assemblies.active?.name || 'All'}
                    options={['All', ...Object.keys(assemblies.byName)]}
                    onChange={(val: string) => {
                        types.deactivateAll();
                        assemblies.setActive(assemblies.byName[val]);
                    }}
                />
            </Header>

            <Body>
                <div className="Assemblies">
                    {assemblies.active ? (
                        <>
                            <Assembly assembly={assemblies.active} />
                            <Types assembly={assemblies.active} />
                        </>
                    ) : (
                        <>
                            <h3>All Assemblies</h3>
                            {assemblies.all.map(assembly => <Assembly key={assembly.name} assembly={assembly} color={assembly.color} />)}
                        </>
                    )}
                </div>
            </Body>
        </Panel>
    );
});
