import { observer } from 'mobx-react';
import { useCallback, useEffect, useState } from 'react';
import { useStores } from '@strategies/stores';

import { StringMap } from '../../util';
import Stores from '../../stores/Stores';



type Selection = {
    width: string;
    left: string;
}

const DEFAULT_SELECTION: Selection = {
    width: '100%', 
    left: '0%',
};

export default observer(function AssemblyRatioAnalysis() {
    const { assemblies, types } = useStores<Stores>();
    const [selection, setSelection] = useState<Selection>(DEFAULT_SELECTION);
    const [container, setContainer] = useState<HTMLDivElement|null>(null);
    const [refs, setRefs] = useState<StringMap<HTMLDivElement>>({});

    const setRef = useCallback((name: string, ref: HTMLDivElement) => {
        setRefs({ ...refs, [name]: ref });
    }, [refs]);

    useEffect(() => setRefs({}), [assemblies.active]);

    useEffect(() => {
        if (types.active && container && refs[types.active.name]) {
            const ref = refs[types.active.name];

            const resize = () => {
                const { width, left } = ref.getBoundingClientRect();
                const containerLeft = container.getBoundingClientRect().left;

                setSelection({
                    width: `${width}px`,
                    left: `${left - containerLeft}px`,
                });
            };

            const resizeObserver = new ResizeObserver(resize);
            resizeObserver.observe(ref);

            return () => resizeObserver.unobserve(ref);
        }
        else {
            setSelection(DEFAULT_SELECTION);
        }
    }, [container, refs, types.active]);

    return (
        <div className="AssemblyRatioAnalysis">
            <div className="ratios" ref={el => el && !container && setContainer(el)}>
                {assemblies.active ? (
                    assemblies.active.types.map((t, i) => t.carbon > 0 ? (
                        <div 
                            key={i}
                            ref={el => el && !refs[t.name] && setRef(t.name, el)}
                            style={{
                                width: `${(t.carbon / assemblies.active!.carbon) * 100}%`,
                                backgroundColor: t.color,
                            }}
                            onClick={() => types.toggleActive(t)}
                        />
                    ) : <></>)
                ) : (
                    assemblies.all.map((a, i) => a.carbon > 0 ? (
                        <div
                            key={i}
                            ref={el => el && !refs[a.name] && setRef(a.name, el)}
                            style={{
                                width: `${(a.carbon / assemblies.carbon) * 100}%`,
                                backgroundColor: a.color,
                            }}
                            onClick={() => assemblies.setActive(a)}
                        />
                    ) : <></>)
                )}
            </div>

            <div 
                className="selection"
                style={selection}
            />
        </div>
    );
});
