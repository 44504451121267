import { EqualityStates, getEquality, formatCarbonNumber } from '../../util';


type InsightProps = {
    name: string;
    leftValue: number;
    rightValue: number;
};

export default function Insight({ leftValue, name, rightValue }: InsightProps) {
    const equality = EqualityStates[getEquality(leftValue - rightValue)];

    return (
        <div className="Widget Insight">
            <h4>{name}</h4>

            <div>
                <span>{formatCarbonNumber(leftValue)}</span>
                <equality.icon className={equality.className} />
                <span>{formatCarbonNumber(rightValue)}</span>
            </div>
        </div>
    );
}
